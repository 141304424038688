import request from './request'
const http = {
  initParams: {
      "channel": "1",
      "version": "1.0.0",
  },
  requstfn(Paramsdata,resolve,reject){
    let initParam = null
    if(Paramsdata.data.version){
      initParam = {
        "channel": "1",
      "version": Paramsdata.data.version,
      }
    }
      Paramsdata.data={
          ...Paramsdata.data,
          ...(initParam||http.initParams),
      }
      let userinfo=localStorage.getItem('userinfo')

      if(userinfo){
          userinfo=JSON.parse(userinfo);
          Paramsdata.data.token=userinfo.token;
          Paramsdata.data.uid=userinfo.uid;
      }
      request(Paramsdata).then((data)=>{
          if(data){
              resolve(data)
          }else {
              reject(11)
          }
      })
  },
  post(parm) {
      return new Promise((resolve, reject) => {
          http.requstfn({
              url:parm.serviceCode,
              method: 'POST',
              data: {
                  ...parm,
              }
          },(data)=>{
              resolve(data)
          },(data)=>{
              reject(data)
          })
      });
  },
  getiupload(parm) {
    return new Promise((resolve, reject) => {
        http.requstfn({
            url:'/system/oss/upload',
            method: 'POST',
            data: {
                ...parm,
            }
        },(data)=>{
            resolve(data)
        },(data)=>{
            reject(data)
        })
    });
  }
}

export default http;