<template>
  <div class="box_mbx">
    <a-breadcrumb>
      <a-breadcrumb-item @click="getBreadcrumb" class="weizhi"
        >当前位置：</a-breadcrumb-item
      >
      <!-- <a-breadcrumb-item v-for="(item,index) in levelList" :key="index"><a :href="item.path">{{item.meta.title}}</a></a-breadcrumb-item> -->
      <a-breadcrumb-item
        v-for="(item, index) in levelList"
        :key="index"
        @click="topath(item.path)"
        ><span class="box_txt">{{ item.meta.title }}</span></a-breadcrumb-item
      >
    </a-breadcrumb>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { ref, watch } from 'vue'
export default {
  name: 'ZjCrumbs',
  setup() {
    const Route = useRoute()
    const Router = useRouter()
    const levelList = ref([])
    getBreadcrumb()
    function getBreadcrumb() {
      let matched = Route.matched.filter((item) => item.name)
      levelList.value = matched
    }
    function topath(path) {
      Router.push({
        path: path,
      })
    }
    let titles = ref(Router.currentRoute.value.meta.title)
    watch(
      () => Route.matched,
      (newl) => {
        levelList.value = newl
      }
    )
    return {
      getBreadcrumb,
      levelList,
      topath,
      titles,
    }
  },
}
</script>

<style scoped>
.box_mbx {
  padding-top: 20px;
  padding-bottom: 20px;
}
.box_txt {
  cursor: pointer;
  font-size: 16px;
}
.weizhi {
  font-size: 16px;
}
</style>
