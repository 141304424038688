<template>
  <div>
    <a-modal v-model:visible="modalVisible" :bodyStyle="bodyStyle" :footer="null">
      <!--   登录框-->
      <template #title>
        <div class="l-title">欢迎登录</div>
      </template>
      <div class="p-lr-30">
        <a-form ref="formRef" :model="formLogin" :rules="rules">
          <a-row>
            <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <a-form-item name="username">
                <a-input
                    class="l-input"
                    v-model:value="formLogin.username"
                    placeholder="请输入手机号/身份证号"
                />
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <a-form-item name="password">
                <a-input
                    type="password"
                    class="l-input"
                    v-model:value="formLogin.password"
                    placeholder="请输入密码"
                />
              </a-form-item>
            </a-col>
            <div class="l-btn">
              <a-button
                  class="l-btn"
                  type="primary"
                  @click="fnClickLogin"
                  :loading="loading"
              >登录</a-button
              >
            </div>
            <div class="l-other">
              <div @click="fnJump(1)" class="cursor-p">没有账号，先注册</div>
              <div @click="fnJump(2)" class="cursor-p">忘记密码</div>
            </div>
          </a-row>
        </a-form>
      </div>
    </a-modal>

  </div>

</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import http from '@/util/http'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'

export default {
  name: 'loginPopup',
  setup(popup,{emit}) {
    const formRef = ref();
    const registerPopupRef=ref();
    let store = useStore()
    let data = reactive({
      loading: false,
      modalVisible: false,
      rules: {
        username: [
          { required: true, message: '请输入手机号/身份证号', trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      bodyStyle: {
        borderRadius: '30px',
      },
      formLogin: {},
      // 跳转忘记密码和注册
      fnJump(num) {
        data.modalVisible = false;
        // setTimeout(()=>{
        //   registerPopupRef.value.loginClick();
        // },500)
        setTimeout(emit('Jump', num), 500)
      },
      // 点击登录按钮
      fnClickLogin() {
        formRef.value
          .validate()
          .then(() => {
            data.loading = true
            http
              .post({
                serviceCode: 'userLogin',
                param: {
                  userName: data.formLogin.username,
                  pwd: data.formLogin.password,
                },
              })
              .then((res) => {
                // 个人信息存储
                localStorage.setItem('userinfo', JSON.stringify(res))
                store.commit('layou/setUserinfo', res)
                message.success(res.message)
                // 存储头像到本地
                store.commit('layou/setavatar', res.userInfo.avatar)
                data.loading = false
                setTimeout(() => {
                  data.modalVisible = false
                  window.location.reload();//刷新当前页面
                }, 500)
              })
          })
          .catch((error) => {
            data.loading = false
            console.log(error)
          })
        setTimeout(()=>{
          data.loading = false;
        },1000)
      },
      // 父级页面调用
      getParentdata(val) {
        console.log(val)
        data.modalVisible = true
      },
    })
    return {
      registerPopupRef,
      formRef,
      ...toRefs(data),
    }
  },
}
</script>
<style scoped>
.cursor-p {
  cursor: pointer;
  font-size: 18px;
}
#form_item_password {
  background-color: #f6f6f6 !important;
}
.l-input {
  height: 50px !important;
  border-radius: 4px;
  background-color: #f6f6f6 !important;
  font-size: 20px !important;
}
.l-other :nth-child(1) {
  text-decoration: underline;
  color: #1168f3 !important;
}
.l-other {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
  color: #6a6a6a;
}
.l-btn {
  width: 100%;
  height: 50px;
  font-size: 20px;
}
:deep (.ant-modal-header) {
  /*border: none !important;*/
  border: 1xp solid red;
}
.ant-modal-header {
  border: 1xp solid red !important;
}
.p-lr-30 {
  padding: 0px 30px 30px 30px;
}
.l-title {
  font-size: 23px;
  font-family: PingFang SC;
  font-weight: 800;
  margin: 10px;
  text-align: center;
  color: #333333;
  line-height: 50px;
}
</style>
