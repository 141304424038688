<template>
  <div>
    <div class="hzHeader">
      <template v-for="(item, index) in props.tabsInfo" :key="item.dictionary">
        <div
          v-if="index !== 0"
          style="width: 1px; border: 1px solid #ccc"
        ></div>
        <div
          style="cursor: pointer"
          :class="checkTab === index ? 'checkBox' : ''"
          @click="checkItem(index, item)"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
    <a-divider style="border: 1px solid #ccc" />
    <div class="hzMain">
      <div
        style="
          height: 260px;
          margin-top: 20px;
          overflow: hidden;
          padding-right: 20px;
        "
      >
        <template v-for="tableItem in tableList" :key="tableItem.hid">
          <div class="hzMain-info">
            <div class="hzMain-info-item">
              <i style="
                  width: 6px;
                  height: 6px;
                  background-color: #333;
                  border-radius: 3px;
                  margin-top: 11px;
                  margin-right: 5px;
                "></i>
              <div @click="toView(tableItem)">
                <a-tooltip>
                  <template #title>{{ tableItem?.articleTitle }}</template>
                  <div
                    style="
                      width: 350px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    "
                  >
                    {{ tableItem?.articleTitle }}
                  </div>
                </a-tooltip>
              </div>
            </div>
            <div style="font-size: 16px; color: #999">
              {{ tableItem?.createTime }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import publicFn from '@/util/publicFn'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
// eslint-disable-next-line no-undef
const props = defineProps({
  tabsInfo: {
    type: Array,
    default: () => [],
  },
})
let store = useStore()
const Router = useRouter()
const checkTab = ref(0)
const tableList = ref([])
const checkItem = (num, item) => {
  checkTab.value = num
   publicFn.post({
     serviceCode: 'getInformationPageList',
     version: '1.1.0',
      param: {
        articleType: item.dictionary,
      },
      page: {
        currentPage: 1,
        showCount: 8,
      },
    })
    .then((res) => {
      tableList.value = res.rows
    })
}
// const toPath = (i) => {
//   Router.push({ path: `/Details/${i}` })
// }
const toView = (record) => {
  if( store.state.urlAh==='hz1'){
    if(record.articleType==='eventDisplay'){
      Router.push({
        path: `/Details/${record.hid}`,
        query: {
          type: 2,
        },
      })
    }else{
      Router.push({
        path: `/DetailScreening`,
        query: {
          searchId: record.hid,
          secondLevelType:record.secondLevelType,
          title: props.tabsInfo[checkTab.value]?.name || '',
        },
      })
    }
  }else{
    Router.push({
      path: `/Details/${record.hid}`,
      query: {
        type: 2,
      },
    })
  }
}
onMounted(() => {
  if(store.state.urlAh==='yt'){
       checkTab.value=1;
      checkItem(1,{dictionary:'eventDisplay'});
  }else{
    publicFn.post({
      serviceCode: 'getInformationPageList',
      version: '1.1.0',
      param: {
        articleType:props.tabsInfo[0].dictionary,
      },
      page: {
        currentPage: 1,
        showCount: 8,
      },
    })
        .then((res) => {
          tableList.value = res.rows
        })
  }

})
</script>
<style scoped>
.hzHeader {
  display: flex;
  height: 22px;
  color: #333;
}
.hzHeader > div {
  /* width: calc(100% / 3); */
  margin-right: 30px;
  text-align: center;
  line-height: 22px;
  font-size: 22px;
  font-weight: 600;
}

.checkBox {
  color: #fa430f !important;
}
.hzMain-info {
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 10px;
}
.hzMain-info-item {
  display: flex;
  cursor: pointer;
  font-size: 20px;
}
.hzMain-info-item :hover{
  color: #40a9ff !important;
}
</style>
