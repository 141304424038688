<template>
  <div class="extension" v-if="false">
      <div class="extension_d">
        <div>
          <img @click="goDetails({
          path:'/special'
          })" class="img" :src="require(`@/assets/img/oneb.png`)" alt="">
        </div>
        <div @click="goDetails({
          path:'/special'
          })">
          <img class="img"  :src="require(`@/assets/img/twob.png`)" alt="">
        </div>
      </div>
  </div>
</template>

<script>
import {reactive, toRefs} from 'vue'
import publicFn from "@/util/publicFn";

export default {
  name: "extension_index",
  setup() {
    let _this = reactive({
      //试看视频
      goDetails: publicFn.routeJump,
    })
    return {
      ...toRefs(_this)
    }
  }
}
</script>
<style scoped>
.extension_d{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.extension_d>div{
  width: 49.5%;
}
.img{
  width: 100%;
  height: 180px;
}
</style>