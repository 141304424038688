<template>
  <div>
    <a-modal
      v-model:visible="visibleto"
      width="40%"
      :title="title"
      :getContainer="getContainer"
      @ok="handleOk"
      :footer="null"
    >
      <!-- <template #title>
        <div>
          <img class="ant_img" src="@\assets\img\tankuanglantiao.png" alt="">
          <div class="baom">{{title}}</div>
        </div>
      </template> -->
      <!-- 关闭图标样式 -->
      <!-- <template #closeIcon>
        <div><close-outlined class="closet"  /></div>
      </template> -->
      <div>
        <div class="boxn">课程信息</div>
        <a-checkbox-group
          v-model:value="modelRef.type"
          style="width: 100%"
          @change="ChangeCheckbox"
        >
          <div class="bn_l" v-for="it in list" :key="it.id">
            <div class="bn_l_n">
              <a-checkbox :value="it.name" name="type"> </a-checkbox>
              <div class="bn_l_n_t">
                <div>{{ it.name }}</div>
                <div class="bn_l_n_t_b">
                  {{ it.year }} | {{ it.Credit }}学时
                </div>
              </div>
            </div>
            <div>
              <img class="bn_img" src="../../assets/img/shanchu.png" alt="" />
            </div>
          </div>
        </a-checkbox-group>
        <div v-if="title == '确认付款'">
          <div class="zf_t">支付方式</div>
          <div class="zf_fs">
            <div class="zf_fs_box lan">
              <img
                class="zf_img"
                src="../../assets/img/weixin.png"
                alt=""
              />微信支付
            </div>
            <div class="zf_fs_box">
              <img
                class="zf_img"
                src="../../assets/img/zhifubao.png"
                alt=""
              />支付宝支付
            </div>
            <div class="zf_fs_box">
              <img
                class="zf_img"
                src="../../assets/img/yinlian.png"
                alt=""
              />银联支付
            </div>
          </div>
        </div>
        <div class="pagebox">
          <a-pagination
            class="pages"
            v-model:current="current"
            :total="total"
            show-less-items
          />
        </div>
        <div class="addbox">
          <div>
            共选中<span class="hong">5</span>门课程，共<span class="hong"
              >38</span
            >学时
          </div>
          <a-button type="primary" @click="addquren">确认报名</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  name: 'BulletFrame',
  props: {
    visiblest: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    title: {
      type: String,
      default: () => {
        return '确认报名'
      },
    },
  },
  components: {},
  setup(props, { emit }) {
    let data = reactive({
      visibleto: props.visiblest,
      getContainer: () => document.querySelector('#app'),
      showModal: () => {
        data.visibleto = true
      },
      handleOk: (e) => {
        console.log(e)
        data.visibleto = false
      },
      list: [
        {
          name: '摄影与设想',
          Credit: '4.0',
          year: '2022年度',
          id: 1,
        },
        {
          name: '数媒视觉设计',
          Credit: '4.0',
          year: '2022年度',
          id: 2,
        },
        {
          name: '现代新媒体技术与务实',
          Credit: '12.0',
          year: '2022年度',
          id: 3,
        },
      ],
      ChangeCheckbox: (e) => {
        console.log(e)
      },
      show: () => {
        data.visibleto = true
      },
      modelRef: [], //多选结果
      current: 2, //分页器当前页数
      total: 50, //分页器总页数
      addquren: () => {
        data.visibleto = false
        emit('callback', '确认报名')
      },
    })

    return {
      ...toRefs(data),
    }
  },
})
</script>

<style scoped>
.lan {
  background: #eff5fd !important;
  border: 1px solid #1979fa !important;
}
.zf_fs_box {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 13px 26px;
  margin-right: 20px;
  cursor: pointer;
}
.zf_img {
  width: 30px;
  height: 30px;
  margin-right: 9px;
}
.zf_fs {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.zf_t {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #333333;
  margin-bottom: 19px;
}
.baom {
  z-index: 999;
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translate(-50%);
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
}
.boxn {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #333333;
  padding-bottom: 24px;
}
.bn_l {
  background: #f2f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 20px;
}
.bn_img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.bn_l_n {
  display: flex;
  align-items: center;
}
.bn_l_n_t {
  margin-left: 10px;
  font-size: 18px;
}
.bn_l_n_t_b {
  font-size: 16px;
  color: #a7a7a7;
  margin-top: 5px;
}
.pagebox {
  position: relative;
  padding-top: 50px;
}
.pages {
  position: absolute;
  bottom: 0;
  right: 0;
}
.ant_img {
  width: 100.1%;
  height: 80px;
  position: absolute;
  left: -1px;
  top: -20px;
}
.addbox {
  font-size: 20px;
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
}
.hong {
  color: red;
}
.closet {
  color: #fff;
}
</style>
