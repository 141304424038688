<template>
  <div class="box">
    <a-modal v-model:visible="visible" title="温馨提示" @ok="handleOk" cancelText="取消" width="600px">
      <div>
        <div class="txtb">广州日报讯 （全媒体记者何雪华 通讯员粤卫信）2月6日深夜，广东省卫生健康委发布来粤返粤公众温馨提示：每 个人是自己健康的第一</div>
        <div>一、主动熟悉政策。返粤前，在国务院小程序上，查询目的地最新防疫政策，按照要求做好有关准备。按照国家春 节防控要求，中高风险所在县区严格限制出行，避免前往中高风险所在县区。中高风险所在地市非必要不出行，确 实要出行，凭48小时核酸检测阴性证明。 二、主动做好防护。返程期间，遵守车站、机场、服务区等交通站场的防控要求，科学佩戴口罩，保持社交距离， 勤洗手，少聚集，尤其在人多的场所，一定要戴好口罩。 三、主动检测核酸。提倡14天内有省外旅居史的人员返粤后48小时内做一次核酸检测，持阴性结果返岗返工。建议 在抵达地火车站、汽车站、机场、高速路服务区检测或住家附近检测。</div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'ZjNotification',
  setup() {
    const visible = ref(true);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = e => {
      console.log(e);
      visible.value = false;
    };

    showModal()
    return {
      visible,
      showModal,
      handleOk,
    };
  },

});
</script>

<style scoped>
.box {
  position: relative;
}
.box_t_t {
  position: absolute;
  top: -100px;
  left: 0;

}
.box_t_img {
  width: 712px;
  height: 187px;
}
.box /deep/ .ant-modal-content{
  width: 860px;
}
.txtb {
  margin-top: 20px;
}
</style>