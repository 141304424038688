let Configuration = {
  shanxi: {
    name:'山西省专技',
    Bottombar:{
      left:[
        {text:'联系我们：400-683-6699 | 地址：山东省济南市历下区齐鲁创意基地5号楼2单元401室'},
        {text:'CopyRight 2021 专业技术人员继续教育培训平台 All Right Rwserved | 备案号：鲁ICP备2022004159号'},
        {text:'广播电视节目制作经营许可证：（鲁）字第2151号  |  增值电信业务经营许可证：鲁B2-20220061'},
    ],
      rigth:[
        {
          region:'中华人民共和国人力资源和社会保障部',
          resources:'山西省各地市人力资源和社会保障局'
        },
        {
          region:'山西省人力资源和社会保障厅',
          resources:'其他地区专技网入口'
        }
      ],
      img:require('@/assets/img/zxdb.png'),
      WeChatimg:require('@/assets/img/htjx.png')
    },
    head: {
      img:''
    }

  },
  heze: {
    name:'菏泽专技'
  }
}

export default Configuration