const examination = {
  // 考试
  namespaced:true,
  state:{
      danlist:[],//单选
      duolist:[],//多选
      panlist:[],//判断
      trainessExamId:'',//试卷id
  },
  mutations:{
    // 修改考试题
    //单选
    setdanlist:(state,paylod)=>{
      state.danlist = paylod
    },
    // 多选
    setduolist:(state,paylod)=>{
      state.duolist = paylod
    },
    // 判断
    setpanlist:(state,paylod)=>{
      state.panlist = paylod
    },
    // 试卷id
    settrainessExamId:(state,paylod)=>{
      state.trainessExamId = paylod
    },
    delquestions:(state,)=>{
      state.danlist = []
      state.duolist = []
      state.panlist = []
      state.trainessExamId = ''
    }
  }
}


export default examination