<template>
<div class="Secondary">

  <a-cascader
  class="cascaderselet"
      v-model:value="value"
      :options="cityoptions"
      :load-data="loadData"
      placeholder="请选择地区"
      @change="cahngeVal"
      :changeOnSelect="true"
  />
      <!-- change-on-select -->
</div>

</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import http from '@/util/http.js'

export default defineComponent({
  name: 'cascader_s',
  props: {
    modelValue:{
      type:Object
    },
    item: {
      type:Object

    }
  },
  setup(props,) {
    let value = ref(props.modelValue)
    const options = ref([])
    // 存储数据
    let cityoptions = ref([])
    // 省、市联机选择器数据获取
    let opcity =(e) => {
      http.post({
        serviceCode: 'getCityList',
        param: {
          cantCode: e
        }
      }).then((res)=>{
        if(e == 'CN') {
          res.rows.map((it,index)=>{
            cityoptions.value[index] = {
              label: it.cantName,
              level:0,
              isLeaf: false,
              value: it.cantCode,
            }
          })

        } else if(cityoptions.value[xiabiao.value].level == 0) {
          res.rows.map((it) => {
            cityoptions.value[xiabiao.value].items = {
              label: it.cantName,
              value: it.cantCode,
            }
          })
        }
      })
    }
    let xiabiao = ref()

    opcity('CN')
    let centshow=()=>{
      value.value = ['']
    }

    const loadData = selectedOptions => {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      // targetOption.loading = true;
      if(selectedOptions.length==1){
        http.post({
          serviceCode: 'getCityList',
          param: {
            cantCode: selectedOptions[0].value
          }
        }).then((res)=>{
          let arr=[];
          // targetOption.loading = false;
          res.rows.map((it) => {
            arr.push({
              label: it.cantName,
              value: it.cantCode,
              level:1,
            })
          })
          targetOption.children = arr
        })
        options.value = [...options.value];
      }
      // else if(selectedOptions.length==2){
      //   http.post({
      //     serviceCode: 'getCityList',
      //     param: {
      //       cantCode: selectedOptions[1].value
      //     }
      //   }).then((res)=>{
      //     let arr=[];
      //     targetOption.loading = false;
      //     res.rows.map((it) => {
      //       arr.push({
      //         label: it.cantName,
      //         value: it.cantCode,
      //         level:1,
      //       })
      //     })
      //     targetOption.children = arr
      //   })
      //   options.value = [...options.value];
      // }
    };
    let cahngeVal = (e,i) => {
      console.log(e,i);
    }
    watch(()=>props.modelValue,()=>{
      if(props.modelValue instanceof Array) {
        value.value = [...props.modelValue]
      }
    })
    return {
      value,
      options,
      loadData,
      opcity,
      cityoptions,cahngeVal,centshow
    };
  },

});
</script>


<style>
.Secondary {
  height: 22px !important;
}
.Secondary .ant-select {
  width: 280px !important;
}
/*  */
.Secondary .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: transparent !important;
  border: 0 solid #000 !important;
}
/* 选择框高度 */
.Secondary .ant-select {
 height: 20px !important;
}
/* 选择框颜色 */
.Secondary .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector  {
border-color: transparent !important;
box-shadow:transparent !important;
}
.Secondary .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: 12px !important;
  font-size: 12px;
}
.tail .shenju .Secondary .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  color: #fff;
  border: 0 solid #000 !important;
  font-size: 12px !important;
}
/* .cascaderselet {
} */
.Secondary .ant-select-selection-placeholder {
  color: #fff;

}
</style>