import router from '../router/index'

router.beforeEach((to, from, next) => {
  let token=localStorage.getItem('userinfo')?JSON.parse(localStorage.getItem('userinfo')).token:null;
   if(!token && to.path.slice(1,9) == 'Personal') {
    next('/Index')
  } else {
    next()
  }
})

let permission={}
export default permission