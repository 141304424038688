<template>
  <div>
    <a-spin :spinning="spinning" tip="加载中...">
      <a-table
          :columns="columns"
          :data-source="dataSource"
          :row-selection="rowSelection()"
          :pagination="pagination"
          @change="tablechange"
          :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
        >
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <div class="editable-row-operations">
              <template v-for="(it, index) in column.list" :key="index">
                <a
                    @click="aclick(it, record)"
                    class="dropdown"
                    v-if="record.paymentStatus == 5 && it.name == '申请退款'"
                >取消退款</a
                >
                <a
                    @click="aclick(it, record)"
                    class="dropdown"
                    v-else
                    :class="it.name == '申请退款'||it.name == '删除' ? 'hui' : ''"
                >{{ it.name }}</a
                >
              </template>
            </div>
          </template>
          <template v-if="column.dataIndex === 'invoiceStatus'">
            <div>
              {{ invoice[record.invoiceStatus] }}
            </div>
          </template>
          <template v-if="column.dataIndex === 'courseType'">
            <div>
              {{ course[record.courseType] }}
            </div>
          </template>
          <template v-if="column.dataIndex === 'paymentStatus'">
            <div>
              {{ payment[record.paymentStatus] }}
            </div>
          </template>
          <template v-if="column.dataIndex === 'inUse'">
            <div :class="record.inUse == 1?'zt_lu':'zt_hong'">
              {{ results[record.inUse] || '未通过'}}
            </div>
          </template>
        </template>
      </a-table>
    </a-spin>
    <Verification Title="申请退款" v-model:show="registertype" width="560px">
      <template #neirong>
        <div class="t_k_txt">请输入退款原因</div>
        <a-textarea
            v-model:value="textvalue"
            placeholder="请输入退款原因"
            :autosize="{minRows: 2, }"
            :rows="4"
        />
        <br/>
        <br/>
        <div class="tuikuan">
          <div class="tx_btnb_r_L" @click="qxqueren">确认</div>
          <div class="tx_btnb_l" @click="registertype = false">取消</div>
        </div>
      </template>
    </Verification>
    <Verification Title="申请开票" v-model:show="Invoictype" width="560px">
      <template #neirong>
        <a-form
            :model="formState"
            name="basic"
            :label-col="{ span: 5 }"
            autocomplete="off"
            @finish="onFinishInvoic"
        >
          <a-form-item
              label="发票类型"
              name="invoiceType"
              :rules="[{ required: true, message: '请选择发票类型' }]"
          >
            <a-select
                v-model:value="formState.invoiceType"
                placeholder="请选择发票类型"
                style="width: 100%"
                :options="topic"
                @change="handleChange"
            ></a-select>
          </a-form-item>

          <!-- <a-form-item
              label="开票人类型"
              name="issueUserType"
              :rules="[{ required: true, message: '请选择开票人类型' }]"
          >
            <a-select
                v-model:value="formState.issueUserType"
                placeholder="请选择开票人类型"
                style="width: 100%"
                :options="Drawer"
                @change="DrawerChange"
            ></a-select>
          </a-form-item> -->
          <a-form-item
              name="infoId"
              label="模板编号"
              :rules="[{ required: true, message: '请输入模板编号' }]"
          >
            <a-select
                v-model:value="formState.infoId"
                show-search
                :filter-option="filterOption"
                option-filter-prop="label"
                placeholder="请选择模板编号"
                :fieldNames="invoiceNames"
                style="width: 100%"
                :options="Invoicelist"
                @change="InvoicelistChange"
            ></a-select>
          </a-form-item>
          <a-form-item
              label="接收邮箱"
              name="email"
              :rules="[{ required: true, message: '请输入接收邮箱' }]"
          >
            <a-input v-model:value="formState.email" placeholder="请输入邮箱"/>
          </a-form-item>
          <a-form-item
              name="note"
              label="备注"
          >
            <a-textarea
                v-model:value="formState.note"
                placeholder="请输入备注"
                :autosize="{minRows: 2, }"
            />
          </a-form-item>

          <a-form-item>
            <div class="Invoicing_box">
              <a-button class="Invoicing_box_btn" type="primary" html-type="submit">申请开票</a-button>
            </div>
          </a-form-item>
        </a-form>
      </template>
    </Verification>

  </div>
</template>

<script>
import publicFn from "@/util/publicFn";
import {cloneDeep} from "lodash-es";
import {defineComponent, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import {Verification} from "@/components/index";
import { message } from "ant-design-vue";

export default defineComponent({
  name: "Stable_index",
  components: {
    Verification,
  },
  props: {
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    api: {
      type: String,
      default: "getOrderPageList",
    },
    rowKey: {
      type: String,
      default: () => {
        return "key";
      },
    },
    transmitvalue: {
      type: Object,
    },
    isCheckShow: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {emit}) {
    let store = useStore();
    let data = reactive({
      //模板列表
      Invoicelist:[],
      Invoiceobj:[],
      //选择开票模板
      InvoicelistChange(e){
        data.formState.email=data.Invoiceobj[e]
      },
      //获取开票模板
      getInvoice() {
        publicFn
            .post({
              serviceCode: 'getInvoiceList',
              param: {
              },
            })
            .then((res) => {
              let arr=[];
              res.rows.map((v)=>{
                arr.push({
                  ...v,
                  value: v.id,
                  label: v.templateName,
                })
                data.Invoiceobj[v.id]=v.email;
              })
              data.Invoicelist=arr;
            })
            .catch(() => {
            });
      },
      // 调取模板接口
      callgetInvoice() {
        data.getInvoice()
        data.getlist();
      },
      Invoictype: false, //开票弹窗开关
      registertype: false, //退款弹框开关
      textvalue: "", //退款原因
      // 申请开票
      formState: {
        note: null, //备注
        issueUserType: null, //开票人类型
        invoiceType: '1', //发票类型
        infoId: null, //模板编号
      },
      // 表格数据
      dataSource: [],
      editableData: {},
      edit: (key) => {
        data.editableData[key] = cloneDeep(
            data.dataSource.filter((item) => key === item.key)[0]
        );
      },
      save: (key) => {
        Object.assign(
            data.dataSource.filter((item) => key === item.key)[0],
            data.editableData[key]
        );
        delete data.editableData[key];
      },
      cancel: (key) => {
        delete data.editableData[key];
      },
      invoice: {
        1: "未开票",
        2: "开票中",
        3: "已开票",
        4: "开票失败",
      },
      payment: {
        1: "待支付",
        2: "已完成",
        3: "已退款",
        4: "部分退款",
        5: "退款审核中",
      },
      course: {
        1: "公需课",
        2: "专业课",
        3: "转岗培训",
      },
      results:{
        0:'未通过',
        1:'通过'
      },
      // loding
      spinning: false,
      // 获取个人信息
      userinfo: store.state.layou.userinfo,
      //页数
      currentPage: 1,
      param: {},
      // 获取订单列表
      getlist: () => {
        data.spinning = true;
        publicFn
            .post({
              serviceCode: props.api,
              param: {
                ...data.param,
              },
              page: {
                showCount: data.pagination.pageSize,
                currentPage: data.pagination.current,
              },
            })
            .then((res) => {
              
              
              data.dataSource = res.rows;
              if(props.api == 'getCanInvoicePageList') {
                let arr = {
                  invoice:res.invoiceTips,
                  sum:res.sum
                }
                emit('invoicdata',arr)
              }
              res.rows.map((item) => {
                item.key = item[props.rowKey];
              });
              data.pagination.total = res.page.totalCount;
              data.spinning = false;
              
            })
            .catch(() => {
              data.spinning = false;
            });
      },
      approveStatus: 1, // 1退款 // 4取消退款
      orderItemId: "", //商品 id
      orderNo: "", //订单编号
      // 列表点击
      kprecord: null, //开票选项赋值存储
      aclick: (item, record) => {
        emit('operateData',item, record)
        switch (item.type) {
          case "refund":
            if (record.paymentStatus == 5) {
              data.approveStatus = 4;
              data.orderItemId = record.orderItemId;
              data.orderNo = record.orderNo;
              // 调取取消退款接口
              data.postrefund();
            } else if(record.paymentStatus == 3) {
              message.error('已退款，请勿重复')
            } else if(record.invoiceStatus == 3) {
              message.error('已开票，不能退款')
            } else {
              data.registertype = true;
              data.approveStatus = 1;
              data.orderItemId = record.orderItemId;
              data.orderNo = record.orderNo;
            }
            break;
            // 申请开票
          case "invoicing":
          if(record.paymentStatus == 3) {
           return message.error('已退款，请勿开票')
          }
            (data.Invoictype = true), //弹窗开关
            (data.kprecord = record);
            data.orderItemIdlist = [record.orderItemId];
            break;
            // 修改发票模板
          case 'modify':
            emit('InvoicInformation',item, record)
           break;
           case 'delete':
            publicFn.post({
              serviceCode:item.api,
              param:{
                id:record.id
              }
            }).then((res)=>{
              message.success(res.message)
              data.getlist()
            })
           break
        }
      },
      // 确认取消
      qxqueren() {
        data.postrefund();
      },
      // 外部传递参数调取数据
      calltion: (from) => {
        data.param = {
          ...from,
        };
        data.getlist();
      },
      // 多选
      rowSelection(){
        if(!props.isCheckShow) return null;
        return{
          checkStrictly: false,
          columnWidth:'60px',
          onChange: (selectedRowKeys) => {
            // selectedRowKeys 是key号   selectedRows 是选中的列表
  
            data.orderItemIdlist = selectedRowKeys;
            emit("transmit", selectedRowKeys);
          },
        }
      },
      // 列表翻页
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
      },
      // 获取开票失败原因
      getfailNotice: () => {
        publicFn
            .post({
              serviceCode: "getInvoiceReason",
            })
            .then(() => {
            });
      },
      // 查询开票结果(发票平台查)
      getInvoiceEnding: () => {
        publicFn
            .post({
              serviceCode: "getInvoiceEnding",
            })
            .then(() => {
            });
      },
      // 申请退款
      postrefund() {
        publicFn
            .post({
              serviceCode: "myOrderRefund",
              param: {
                approveStatus: data.approveStatus, //审核状态
                approveContent: data.textvalue, //申请原因
                order: [
                  {
                    orderItemId: data.orderItemId, //订单商品id
                    orderNo: data.orderNo, //订单编号
                  },
                ],
              },
            })
            .then(() => {
              // data.pagination.total = 1;
              // data.pagination.current = 1;
              // data.pagination.pageSize = 10;
              data.textvalue = "";
              data.registertype = false;
              data.getlist();
            });
      },
      orderItemIdlist: [],
      // 点击提交申请开票接口
      onFinishInvoic() {
        publicFn
            .post({
              serviceCode: "billingService",
              param: {
                  orderSnList: data.orderItemIdlist,
                  note: data.formState.note,
                  invoiceType: data.formState.invoiceType,
                  // issueUserType: data.formState.issueUserType,//开票类型
                infoId: data.formState.infoId,
                email:data.formState.email
              },
            })
            .then((res) => {
              // data.pagination.total = 1;
              // data.pagination.current = 1;
              // data.pagination.pageSize = 10;
              message.success(res.message)
              data.Invoictype = false;
              // 成功后回调
              data.getlist();
              // data.getInvoiceEnding();
            });
      },
      // 重新刷新表格
      getshuaxin() {
        data.pagination.total = 1;
        data.pagination.current = 1;
        data.pagination.pageSize = 10;
        data.getlist();
      },
      handleChange() {
      },
      // 点击开票人
      DrawerChange() {
      },
      // 课程类型
      topic: [
        {
          value: "1",
          label: "增值税普票",
        },
        // {
        //   value: "2",
        //   label: "增值税专票",
        // },
      ],
      // 开票人类型
      Drawer: [
        {
          value: "1",
          label: "公司",
        },
        {
          value: "2",
          label: "个人",
        },
      ],
      //列表分页
      tablechange:(res)=>{
      data.pagination.pageSize = res.pageSize
      data.pagination.current=res.current;
      data.getlist();
    },
     // 类型选择
      invoiceNames:{
        label: 'invoiceHeader', value: 'id'
      },
    });
    data.getlist();
    data.getInvoice();
    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      ...toRefs(data),
      filterOption,
    };
  },
});
</script>

<style>
.ant-table.ant-table-bordered
> .ant-table-container
> .ant-table-content
> table,
.ant-table.ant-table-bordered
> .ant-table-container
> .ant-table-header
> table {
  border-top: 0;
}

.ant-table-thead > tr > th {
  padding-top: 19px !important;
  padding-bottom: 18px !important;
  background: #f5f6f6 !important;
}

.table-striped {
  z-index: 100;
  background: #f5f6f6 !important;
}

.ant-table-cell-fix-right {
  background: transparent !important;
}

/* .ant-table-striped :deep(.table-striped) td {
  background-color: #f5f6f6 !important;
} */
</style>

<style scoped>
.tuikuan {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.tx_btnb_l {
  border: 1px solid #ccc;
  padding: 5px 35px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
}

.tx_btnb_r_L {
  padding: 5px 35px;
  margin-left: 20px;
  border: 1px solid #1168f2;
  background: #1168f2;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
}

.empty {
  margin-top: 30px;
}

.t_k_txt {
  font-size: 18px;
  margin-bottom: 20px;
}

.dropdown {
  margin-right: 10px;
}

/* .dropdown::after {
  content: '|';
  margin-left: 10px;
} */
.dropdown:nth-child(1)::after {
  content: "|";
  margin-left: 10px;
  color: #6d6d6d;
}

.hui {
  color: #6d6d6d;
}

.Invoicing_box {
  text-align: right;
}

.Invoicing_box_btn {
  padding: 0px 20px;
  height: 45px;
  line-height: 40px;
  font-size: 18px;
}
.zt_lu {
  font-size: 16px;
font-weight: 500;
color: #1BC34F;
}
.zt_hong {
font-size: 16px;
font-weight: 500;
color: #DC2525;
}
</style>
