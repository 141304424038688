<template>
    <a-modal v-model:visible="registertype"  :maskClosable="false" :footer="null"   width="650px" :closable="false">
      <template #title>
        <div class="titlebox">忘记密码</div>
      </template>
        <a-spin :spinning="spinning" tip="加载中...">
          <a-form
              :model="register"
              @finish="registeronFinish"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span:16}"
          >
            <a-form-item
                label="手机号码"
                name="phone"
                :rules="[{ required: true, validator: phonerule }]"
                placeholder="请输入手机号"
            >
              <a-input v-model:value="register.phone" placeholder="请输入手机号"/>
            </a-form-item>
            <a-form-item
                label="手机验证码"
                name="phoneCode"
                :rules="[{ required: true, message: '请输入验证码!' }]"
                placeholder="请输入验证码"
            >
              <div class="phoneCode">
                <div class="phoneCode_l">
                  <a-input v-model:value="register.phoneCode" placeholder="请输入验证码"/>
                </div>
                <div class="phoneCode_r" @click="sendPhoneCode">{{ btnText }}</div>
              </div>
            </a-form-item>
            <a-form-item
                label="新密码"
                name="pwd"
                :rules="[{ required: true, message: '请输入新密码!' }]"
            >
              <a-input-password v-model:value="register.pwd" placeholder="请输入密码"/>
            </a-form-item>
            <a-form-item
                label="身份证号"
                name="identityNo"
                :rules="[{ required: true, validator:phoneNumberCheck}]"
            >
              <a-input v-model:value="register.identityNo" placeholder="请输入身份证号"/>
            </a-form-item>
            <!-- <a-form-item
                label="确认密码"
                name="pwdc"
                :rules="[{ required: true,validator: validatePass2}]"
            >
              <a-input v-model:value="register.pwdc" placeholder="请输入密码"/>
            </a-form-item> -->
            <a-form-item :wrapper-col="{ span: 14, offset: 8 }">
              <div class="fg_pwd_button">
                 <a-button class="fg_pwd_btn_l" @click="registertype=false" size="large">取消</a-button>
                 <a-button class="fg_pwd_btn_l" style="margin-left: 15px; witdh:100px" type="primary" html-type="submit" size="large">修改</a-button>
              </div>
            </a-form-item>
          </a-form>
        </a-spin>
    </a-modal>
</template>

<script>

import { reactive, toRefs } from '@vue/reactivity'
import publicFn from '@/util/publicFn'
import {message} from 'ant-design-vue';
export default {
  name:'Password_index',
  components:{
  },
  setup() {
    let data = reactive({
      //弹框控制
      registertype: false,
      spinning: false,
      //点击弹框
      registershow() {
        data.registertype = true;
      },
      // 表单
      register:{},
      // 提交
      registeronFinish:(val)=>{
        // return
        data.spinning=true;
        publicFn.post({
          serviceCode: "updatePassword",
          param: {
            ...val
          }
        }).then((res) => {
          data.spinning=false;
          message.success(res.message);
          data.registertype=false;
        }).catch(()=>{
          data.spinning=false;
          data.registertype=false;
        })
      },
      isClick: false,
      currentTime: 60,
      btnText: '发送验证码',
      //  点击发送验证码
      //发送验证码
      sendPhoneCode() {
        if (data.isClick) {
          return false;
        }
        //验证手机号
        if (!data.yzphone()) {
          return false;
        }
        data.isClick = true;
        let currentTime = data.currentTime;
        publicFn.post({
          serviceCode: "sendPhoneCode",
          param: {
            phone: data.register.phone,
            type: 2
          }
        }).then((res) => {
          let timeInterval = setInterval(function () {
            currentTime--;//倒计时
            data.btnText = currentTime + '';
            if (currentTime <= 0) {
              clearInterval(timeInterval)//重置倒计时
              data.btnText = '发送验证码';
              data.currentTime = 60;
              data.isClick = false;
            }
          }, 1000);
          message.success(res.message);
        }).catch(()=>{
          data.isClick = false;
        })
      },
      //手机号正则
      phonerule: async (Rule, value) => {
        if (value === '') {
          return Promise.reject('请输入手机号');
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {

          return Promise.reject("手机号格式不正确!");
        } else {
          return Promise.resolve();
        }
      },
      //发送验证码验证手机号
      yzphone() {
        if (data.register.phone === '') {
          message.error('请输入手机号');
          return false
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(data.register.phone)) {
          message.error('手机号格式不正确');
          return false
        } else {
          return true;
        }
      },
      //确认密码正则
      validatePass2: async (Rule, value) => {
        if (value === '') {
          return Promise.reject('请输入密码');
        } else if (value !== data.register.pwd) {
          return Promise.reject("两次密码不一致!");
        } else {
          return Promise.resolve();
        }
      },
      //身份证号正则
      phoneNumberCheck: async (Rule, value) => {
        if (value === '') {
          return Promise.reject('请输入身份证号');
        } else if (!/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)) {
          return Promise.reject("身份证号格式不正确!");
        } else {
          return Promise.resolve();
        }
      },
    })


    return {
      ...toRefs(data)
    }
  }
}
</script>

<style scoped>
.titlebox {
  font-size: 23px;
  font-family: PingFang SC;
  font-weight: 800;
  text-align: center;
  margin: 10px;
}
.phoneCode {
  display: flex;
  justify-content: space-between;
}

.phoneCode_l {
  width: calc(100% - 100px);
}

.phoneCode_r {
  width: 100px;
  background: #40a9ff;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  height: 32px;
}
.fg_pwd_button {
  text-align: right;
}
.fg_pwd_btn_l {
  width: 100px;
}
</style>