<template>
  <div class="boxs" v-if="list.length>0">
    <div class="ntime bom" >
      <div class="ntime_txt" v-if="Column.title">{{ Column.title }}:</div>
      <div class="">
        <div class="key" :class="switchtype?'key_active':''" @click="switchtypeclick">
          {{keyvalue && keyvalue.itemName}}
          <span class="key_span">
            <DownOutlined v-if="!switchtype" />
            <UpOutlined v-else/>
          </span>
        </div>
      </div>
    </div>
    <div class="key_content" v-if="switchtype">
      <div class="key_content_div">
          <div class="ntime_txt_t" :class="keys === index?'key_active':''"
                  v-for="(item,index) in list" :key="index"
                 @click="clickValue(item,index)">{{ item.itemName }}
          </div>
      </div>
    </div>
<!--    <div class="ntime_txt_t" :class="keys === index?'lan':''"-->
<!--         v-show="index<listlength" v-for="(item,index) in list" :key="index"-->
<!--         @click="clickValue(item,index)">{{ item.itemName }}-->
<!--    </div>-->
<!--    <div class="listmore ntime_txt_t" @click="openCourse" v-if="list.length>17">-->
<!--      {{listlength>17?'收起':'展开'}}-->
<!--    </div>-->
  </div>
</template>

<script>
import {reactive, toRefs} from 'vue'
import publicFn from '@/util/publicFn.js'
import {
  DownOutlined,UpOutlined
} from '@ant-design/icons-vue';
import {watch} from "@vue/runtime-core";
export default {
  components: {
    DownOutlined,UpOutlined
  },
  name: 'Category_index',
  props: {
    column: {
      type: Object,
    },
    footer: {
      type: String
    },
    switchshow: {
      type: Boolean
    },
    index: {
      type: Number
    },
  },
  setup(props, {emit}) {
    let data = reactive({
      keys: 0,
      listlength: 17,
      keyvalue: {itemName:'全部类型'},
      switchtype: false,
      Column: props.column, // 外部传递参数
      list: [{itemName:'全部类型'}],
      //展开 收起
      openCourse(){
        if(data.listlength>17){
          data.listlength=17;
        }else {
          data.listlength=data.list.length;
        }
      },
      switchtypeclick(){

        data.switchtype=!data.switchtype;
        emit("switchback",{
          index:props.index,
          type:data.switchtype
        })
      },
      //获取字典值数据
      getCourseScreen() {
        publicFn.post({
          serviceCode: "getCourseScreen",
          param: {
            dictCode: props.column.api
          },
        }).then((res) => {
          data.list = res.rows;
          data.keyvalue=res.rows[0];
        })
        // if(localStorage.getItem(props.column.api) && localStorage.getItem(props.column.api)!='[]'){
        //   data.list = JSON.parse(localStorage.getItem(props.column.api));
        // }else {
        //
        // }

      },
      clickValue(it, index) {
        data.keys = index;
        data.keyvalue=it;
        data.switchtype = false
        emit('update:val', it.itemValue)
        emit('transmitValue', it.itemValue)
      }
    })

    // 监听
    watch(()=>props.switchshow,(newl)=>{
      if(!newl){
        data.switchtype=false;
      }
    })
    data.getCourseScreen();
    return {
      ...toRefs(data)
    }
  }
}
</script>

<style scoped>
.key_active{
  color: #1168F3 !important;
}
.key_content{
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 30px 1px #ccc;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 80px;
  width: 100%;
}
.key_content_div{
  display: flex;
  flex-wrap: wrap;
}
.listmore{
  color: #1168F3 !important;
}
.lan {
  background: #1168F3;
  border-radius: 4px;
  color: #fff !important;
}

.boxs {
  background: #fff;
}

.ntime {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  padding: 20px;
  word-wrap: break-word;
  word-break: break-all;
}
.key{
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-right: 30px;
  cursor: pointer;
}
.ntime_txt {
  font-size: 17px;
  margin-top: 3px;
  cursor: pointer;
  width: 100px;
  height: 34px;
  line-height: 34px;
  border-radius: 5px;
  font-family: PingFang SC;
  font-weight: 500;
  margin-bottom: 10px;
}

.ntime_txt_t {
  cursor: pointer;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-right: 29px;
  padding: 5px 10px;
  /*height: 30px;*/
  /*line-height: 30px;*/
  margin-bottom: 18px;
}

</style>