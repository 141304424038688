const layou = {
  namespaced:true,
  state:{
    token:localStorage.getItem('userinfo')?JSON.parse(localStorage.getItem('userinfo')).token:'',
    userinfo:localStorage.getItem('userinfo')?JSON.parse(localStorage.getItem('userinfo')):'',
    Displays:[],//页面展示信息 尾部  头部  图片
    avatar:localStorage.getItem('Imageavatar')?localStorage.getItem('Imageavatar')=='null'?null:localStorage.getItem('Imageavatar'):'',//头像
    newslist:[], //未读通知公告
    accountinfor:{},//修改后账户信息
  },
  mutations:{
    // 修改存储token
    setToken:(state,paylod)=>{
      state.token = paylod
    },
    // 修改存储用户个人信息
    setUserinfo:(state,paylod)=>{
      state.token = paylod.token
      state.userinfo = paylod
    },
    // 存储页面展示信息
    setDisplays:(state,paylod) => {
      state.Displays = paylod
    },
    // 存储头像
    setavatar:(state,paylod)=>{
      state.avatar = paylod
      localStorage.setItem('Imageavatar', paylod)
    },
    setnewslist(state,paylod) {
      state.newslist = paylod
    },
    // 存储修改账号后的信息
    setaccountinfor(state,paylod) {
      state.accountinfor = paylod
    },
   
    removeToken:(state)=>{
      state.token = '',
      state.userinfo = '',
      localStorage.clear()
    }
  }
}


export default layou