<template>
  <div class="professor">
    <div class="professor_t">
      <div class="professor_t_l">{{title}}</div>
      <div class="professor_t_r" v-if="more == 'true'" @click="goMore(topage)">查看更多 ></div>
    </div>
    <div class="professor_boder">
      <div class="professor_boder_c"></div>
    </div>
    <div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import publicFn from '@/util/publicFn.js'
import { reactive, toRefs } from '@vue/reactivity'

export default {
  name:'doubt_index',
  props: {
    title: {
      type:String,
      default: '政策公告'
    },
    more: {
      type:String,
      default: ()=>{
        return 'true'
      }
    },
    topage:{
      type:String,
      default:'/Policyadvice'
    }
  },
  setup() {
    let data = reactive({
      goMore(e) {
        publicFn.routeJump({
          path:e
        })
      }
    })
    return {
      ...toRefs(data)
    }
  }
}
</script>

<style scoped>
.professor {
  background: #fff;
  padding: 29px;
  border-radius: 10px;
}
.professor_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #E5E5E5; */
}
.professor_t_l {
font-size: 32px;
font-family: YouSheBiaoTiHei;
font-weight: 400;
color: #333333;
line-height:normal;
}
.professor_t_r {
  cursor: pointer;
  font-size: 16px;
font-family: PingFang SC;
font-weight: 500;
color: #1168F3;
}
.professor_boder {
  margin-top: 12px;
  width: 100%;
  height: 1px;
background: #E5E5E5;
position: relative;
}
.professor_boder_c {
  position: absolute;
  left: 0;top: -5px;
  width: 100px;
height: 5px;
background: #1168F3;
}
</style>